import Dashboard from '../views/dashboard/dashboard.vue';
import Orders from '../views/dashboard/orders/orders.vue';
import Order from '../views/dashboard/orders/order.vue';
import EditOrder from '../views/dashboard/orders/edit.vue';
// import PendingOrders from "../views/dashboard/orders/pending.vue";
import RequestLayout from '../components/layout/RequestLayout.vue';
import Profile from '../views/dashboard/settings/profile.vue';
import Organisation from '../views/dashboard/settings/organisation.vue';
import Users from '../views/dashboard/users/users.vue';
import User from '../views/dashboard/team/show.vue';
import Team from '../views/dashboard/team/index.vue';
import ArchivedUsers from '../views/dashboard/users/achieved.vue';
import RequestPurchase from '../views/dashboard/requests/purchase.vue';
import usersRequest from '../views/dashboard/users/usersRequest.vue';
import JumiaLayout from '../views/dashboard/jumia/layout/layout.vue';
import KongaLayout from '../views/dashboard/konga/layout/layout.vue';
import XeroxLayout from '../views/dashboard/xerox/layout/layout.vue';
import Wallet from '../views/dashboard/wallet.vue';
import Transactions from '../views/dashboard/transactions.vue';
import Notifications from '../views/dashboard/notifications.vue';

import JumiaRoutes from './jumia';
import KongaRoutes from './konga';
import XeroxRoutes from './xerox';

import SupplierLayout from '../views/supplier/layout/layout.vue';
import SupplierRoutes from './supplier';

import ApprovalCircle from '../views/dashboard/approvalCircle/index.vue';
import Help from '../views/dashboard/help/index.vue';
import HelpDesk from '../views/dashboard/help/helpDesk.vue';

const dashboardRoutes = [
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/dashboard/onboarding/share.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/organisation',
    name: 'organisation',
    component: Organisation,
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions,
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
  },

  {
    path: '/overview',
    name: 'overview',
    component: () => import('@/views/dashboard/budget/index.vue'),
  },

  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/dashboard/billing/billing.vue'),
  },

  {
    path: '/approval-circle',
    name: 'approvalCircle',
    component: ApprovalCircle,
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
  },
  {
    path: '/help-desk',
    name: 'helpDesk',
    component: HelpDesk,
  },

  {
    path: '/payees',
    component: () => import('@/views/dashboard/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'payees',
        component: () => import('@/views/dashboard/payee/index.vue'),
      },
      {
        path: '/payee/create',
        name: 'createPayee',
        component: () => import('@/views/dashboard/payee/create.vue'),
      },
      {
        path: '/payee/edit/:id',
        name: 'editPayee',
        component: () => import('@/views/dashboard/payee/create.vue'),
      },
    ],
  },

  {
    path: '/team',
    name: 'team',
    component: Team,
  },

  {
    path: '/team/:id',
    name: 'showTeam',
    component: () => import('@/views/dashboard/team/show.vue'),
  },

  {
    path: '/users',
    component: () => import('@/views/dashboard/users/layout.vue'),
    children: [
      {
        path: '/users',
        name: 'users',
        component: Users,
      },
      {
        path: '/user/:id',
        name: 'user-view',
        component: User,
      },
      {
        path: '/users-request',
        name: 'usersRequest',
        component: usersRequest,
      },
      {
        path: '/archived-users',
        name: 'ArchivedUsers',
        component: ArchivedUsers,
      },
    ],
  },

  {
    path: 'orders',
    component: () => import('@/views/dashboard/orders/layout.vue'),
    children: [
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
      },
      // {
      //   path: '/pending-orders',
      //   name: 'pendingOrders',
      //   component: PendingOrders,
      // },
      // {
      //   path: '/endorse-orders',
      //   name: 'endorseOrders',
      //   component: () => import('@/views/dashboard/orders/endorse.vue'),
      // },
      {
        path: '/order/:id',
        name: 'order',
        component: Order,
      },
      {
        path: '/order/edit/:id',
        name: 'edit-order',
        component: EditOrder,
      },
    ],
  },
  {
    path: 'pending',
    component: () => import('@/views/dashboard/requests/layout.vue'),

    children: [
      {
        path: '/pending-payments-approval',
        name: 'pendingPaymentsApproval',
        component: () => import('@/views/dashboard/pending/approval/payments.vue'),
        meta: {
          parent: 'pendingApprovalsEndorsement',
        },
      },
      {
        path: '/pending-purchases-approval',
        name: 'pendingPurchasesApproval',
        component: () => import('@/views/dashboard/pending/approval/purchases.vue'),
        meta: {
          parent: 'pendingApprovalsEndorsement',
          subParent: '',
        },
      },
      {
        path: '/pending-payments-endorsement',
        name: 'pendingPaymentsEndorsement',
        component: () => import('@/views/dashboard/pending/endorsement/payments.vue'),
        meta: {
          parent: 'pendingApprovalsEndorsement',
        },
      },
      {
        path: '/pending-purchases-endorsement',
        name: 'pendingPurchasesEndorsement',
        component: () => import('@/views/dashboard/pending/endorsement/purchases.vue'),
        meta: {
          parent: 'pendingApprovalsEndorsement',
        },
      },
    ],
  },

  {
    path: 'requests',
    component: () => import('@/views/dashboard/requests/layout.vue'),
    children: [
      {
        path: '/offline-payments',
        name: 'OfflinePayments',
        component: () => import('@/views/dashboard/requests/payments/records/index.vue'),
        meta: {
          parent: 'requests',
        },
      },
      {
        path: '/payment/offline/create',
        name: 'CreateOfflinePayment',
        component: () => import('@/views/dashboard/requests/payments/records/create.vue'),
        meta: {
          parent: 'requests',
        },
      },

      {
        path: '/payments',
        name: 'payments',
        component: () => import('@/views/dashboard/requests/payments/online/index.vue'),
        meta: {
          parent: 'requests',
        },
      },

      {
        path: '/payments/create',
        component: () => import('@/views/dashboard/requests/payments/online/create.vue'),
        name: 'CreatePayment',
        meta: {
          parent: 'requests',
        },
      },
      {
        path: '/payments/saved/:id',
        component: () => import('@/views/dashboard/requests/payments/online/create.vue'),
        name: 'SavedPayment',
        meta: {
          parent: 'requests',
        },
      },
      {
        path: '/payment/:id',
        component: () => import('@/views/dashboard/requests/payments/show.vue'),
        name: 'ShowPayment',
        meta: {
          parent: 'requests',
        },
      },
      {
        path: '/pending-payments',
        name: 'pendingPayments',
        component: () => import('@/views/dashboard/payments/pending.vue'),
        meta: {
          parent: 'requests',
        },
      },
      {
        path: '/endorse-payments',
        name: 'endorsePayments',
        component: () => import('@/views/dashboard/payments/endorse.vue'),
        meta: {
          parent: 'requests',
        },
      },

      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
          parent: 'requests',
        },
      },
    ],
  },

  {
    path: 'bills',
    name: 'bills',
    component: () => import('@/views/dashboard/bills/index.vue'),
  },
  {
    path: 'bill/:id',
    name: 'bill',
    component: () => import('@/views/dashboard/bills/show.vue'),
  },

  {
    path: '/items',
    name: 'items',
    component: () => import('@/views/dashboard/item/index.vue'),
  },

  {
    path: 'products',
    name: 'products',
    component: () => import('@/views/dashboard/products.vue'),
  },
  {
    path: 'jumia',
    component: JumiaLayout,
    children: JumiaRoutes,
  },

  {
    path: 'konga',
    component: KongaLayout,
    children: KongaRoutes,
  },
  {
    path: 'xerox',
    component: XeroxLayout,
    children: XeroxRoutes,
  },
  {
    path: 'supplier',
    component: SupplierLayout,
    children: SupplierRoutes,
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestLayout,
    children: [
      {
        path: 'purchase',
        name: 'requests.purchase',
        component: RequestPurchase,
      },
      {
        path: 'airtime',
        name: 'purchase.airtime',
        component: () => import('@/views/dashboard/requests/purchases/airtime.vue'),
        meta: { subTitle: 'Airtime Purchase', showHeader: false },
      },
      {
        path: 'smileairtime',
        name: 'purchase.smileairtime',
        component: () => import('@/views/dashboard/requests/purchases/smile-airtime.vue'),
        meta: { subTitle: 'Airtime Purchase', showHeader: false },
      },
      {
        path: 'airtime/:id/show',
        name: 'purchase.airtime.show',
        component: () => import('@/views/dashboard/requests/purchases/show-airtime-details.vue'),
        meta: { showHeader: false },
      },
      {
        path: 'cabletv-purchase',
        name: 'purchase.cabletv-purchase',
        component: () => import('@/views/dashboard/requests/purchases/cabletv/purchase.vue'),
        meta: { subTitle: 'Cable TV Purchase', showHeader: false },
      },
      {
        path: 'power-purchase',
        name: 'purchase.power-purchase',
        component: () => import('@/views/dashboard/requests/purchases/power/purchase.vue'),
        meta: { subTitle: 'Power Utilities', showHeader: false },
      },
      {
        path: 'data-purchase',
        name: 'purchase.data-purchase',
        component: () => import('@/views/dashboard/requests/purchases/data-purchase/purchase.vue'),
        meta: { subTitle: 'Data Purchase', showHeader: false },
      },
      {
        path: 'smile-data-purchase',
        name: 'purchase.smile-data-purchase',
        component: () => import('@/views/dashboard/requests/purchases/data-purchase/smile-purchase.vue'),
        meta: { subTitle: 'Data Purchase', showHeader: false },
      },
      {
        path: 'spectranet-data-purchase',
        name: 'purchase.spectranet-data-purchase',
        component: () => import('@/views/dashboard/requests/purchases/data-purchase/spectranet-purchase.vue'),
        meta: { subTitle: 'Data Purchase', showHeader: false },
      },
    ],
  },
];
export default dashboardRoutes;
