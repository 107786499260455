<template>
  <div class="layout-dashboard">
    <ILoader v-if="loader" />
    <Offline />
    <Navbar />
    <div class="d-flex wrapper" id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper" @click="hideSidebar()">
        <!-- <div
          class="kyc-banner alert alert-info d-flex justify-content-between align-items-center flex-wrap"
          style=""
          v-if="user && (!user.bvn_verified || user.bvn_verified == 0) && (checkPermissions(44, user.role_id) || checkPermissions(24, user.role_id))"
        >
          <div style="">
            <span class="f-13"
              >Enjoy early access to our latest updates. Log in through our beta
              site instead.
              <a href="https://beta.buysimply.app/dashboard">Visit now</a
              >.</span
            >
          </div>
        </div>
        <div class="kyc-banner alert alert-info d-flex justify-content-between align-items-center flex-wrap">
          <span class="f-13">Welcome to our beta site. Your feedback on our updates will make it better. </span>
        </div> -->

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/dashboard/sidebar.vue';
import Offline from '@/components/dashboard/offline.vue';
import Navbar from '@/components/dashboard/navbar.vue';
import ILoader from '@/components/dashboard/loader.vue';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      loader: (context) => context.loader,
      user: (context) => context.user,
    }),
  },
  components: {
    Sidebar,
    Navbar,
    ILoader,
    Offline,
  },

  watch: {},
  data() {
    return {
      timer: '',
      showKyc: false,
    };
  },
  methods: {
    hideSidebar() {
      document.body.classList.remove('sb-sidenav-toggled');
    },
    countdownTimer() {
      window.setInterval(() => {
        var countDownDateTime = new Date('2024-01-21').getTime();
        var now = new Date().getTime();
        var remaining = 'Timer has expired.';
        var timeLeft = countDownDateTime - now;

        if (timeLeft > 0) {
          const parts = {
            days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timeLeft / 1000 / 60) % 60),
            seconds: Math.floor((timeLeft / 1000) % 60),
          };
          remaining = Object.keys(parts)
            .map((part) => {
              return `${parts[part] < 10 ? '0' + parts[part] : parts[part]} ${part != 'seconds' ? ':' : ''}`;
            })
            .join(' ');
        }
        this.timer = remaining;
      }, 1000);
    },
  },

  created() {
    // this.countdownTimer();
    this.$store.commit('getUser');
    if (this.$store.state.user.role_id == 0) {
      window.location = '/pending';
    }
    if (this.$store.state.user.role_id == 1000) {
      window.location = '/archived-pending';
    }
    setTimeout(() => {
      this.showKyc = true;
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
#page-content-wrapper {
  background: #fafafe;
  padding: 26px 16px;
  margin-top: 52px;
  transition: 0.3s ease-in;
  min-height: 95vh;
}
@media (max-width: 576px) {
  #page-content-wrapper {
    padding: 20px 13px;
  }
}
@media (min-width: 768px) {
  #page-content-wrapper {
    margin-left: 15em;
  }
}
</style>
